const socialLinks = [
  {
    linkType: 'twitter',
    username: 'am_pra_veen',
    socialLink: 'https://twitter.com/', 
  },
  {
    linkType: 'github',
    username: 'praveen-me',
    socialLink: 'https://github.com/'
  },
  {
    linkType: 'linkedin',
    username: 'praveen-me',
    socialLink: 'https://linkedin.com/in/'
  },
];

export default socialLinks;